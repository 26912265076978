import { Link, useLocation } from 'react-router-dom';
import { ROLE, SIDEBAR_TITLE, SYSTEM_PATH } from '../../core/configs/constants';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { observer } from 'mobx-react';
import { useStore } from '../../core/utils/hook';

const SideBar = observer(() => {
    const location = useLocation();
    const { authStore: { userInfo }, clearAllStore } = useStore();

    const handleMenuClick = () => {
        clearAllStore();
    };

    const isActive = ((path) => {
        if (path === SYSTEM_PATH.CARD_MANAGEMENT) {
            return location.pathname.includes(SYSTEM_PATH.CARD_MANAGEMENT) && !location.pathname.includes(SYSTEM_PATH.CARD_TYPE_MANAGEMENT);
        } else if (path === SYSTEM_PATH.DASHBOARD) {
            return location.pathname === SYSTEM_PATH.HOME || location.pathname.includes(SYSTEM_PATH.DASHBOARD);
        }
        return location.pathname.includes(path);
    });

    const menuItems = [
        {
            show: true,
            icon: 'fa-chart-line',
            path: SYSTEM_PATH.DASHBOARD,
            title: SIDEBAR_TITLE.DASHBOARD,
            condition: userInfo?.role !== ROLE.STAFF.key
        },
        {
            show: true,
            icon: 'fa-user',
            path: SYSTEM_PATH.USER_MANAGEMENT,
            title: SIDEBAR_TITLE.USER_MANAGEMENT
        },
        {
            show: true,
            icon: 'fa-layer-group',
            path: SYSTEM_PATH.CARD_TYPE_MANAGEMENT,
            title: SIDEBAR_TITLE.CARD_TYPE_MANAGEMENT
        },
        {
            show: true,
            icon: 'fa-tablet fa-rotate-180',
            path: SYSTEM_PATH.CARD_MANAGEMENT,
            title: SIDEBAR_TITLE.CARD_MANAGEMENT
        },
        {
            show: true,
            isSubMenu: true,
            icon: 'fa-money-check-dollar',
            title: SIDEBAR_TITLE.PAYMENT_MANAGEMENT.LABEL,
            subMenuItems: [
                {
                    path: SYSTEM_PATH.CARD_PAYMENT_MANAGEMENT,
                    title: SIDEBAR_TITLE.PAYMENT_MANAGEMENT.CARD
                },
                {
                    path: SYSTEM_PATH.HOLDER_PAYMENT_MANAGEMENT,
                    title: SIDEBAR_TITLE.PAYMENT_MANAGEMENT.HOLDER
                },
                {
                    path: SYSTEM_PATH.MEMBERSHIP_PAYMENT_MANAGEMENT,
                    title: SIDEBAR_TITLE.PAYMENT_MANAGEMENT.MEMBERSHIP
                }
            ]
        },
        {
            show: true,
            icon: 'fa-file-signature',
            path: SYSTEM_PATH.SUBSCRIPTION_CONTRACT_MANAGEMENT,
            title: SIDEBAR_TITLE.SUBSCRIPTION_CONTRACT_MANAGEMENT,
            condition: userInfo?.role !== ROLE.STAFF.key
        },
        {
            show: true,
            icon: 'fa-envelope',
            path: SYSTEM_PATH.INQUIRY_MANAGEMENT,
            title: SIDEBAR_TITLE.INQUIRY_MANAGEMENT
        },
        {
            show: true,
            icon: 'fa-envelope-circle-check',
            path: SYSTEM_PATH.TEMPLATE_EMAIL,
            title: SIDEBAR_TITLE.TEMPLATE_EMAIL,
            condition: userInfo?.role !== ROLE.STAFF.key
        },
        {
            show: true,
            isSubMenu: true,
            icon: 'fa-gear',
            title: SIDEBAR_TITLE.GENERAL_SETTING.LABEL,
            condition: userInfo?.role !== ROLE.STAFF.key,
            subMenuItems: [
                {
                    path: SYSTEM_PATH.GENERAL_SETTING_TERM,
                    title: SIDEBAR_TITLE.GENERAL_SETTING.TERMS
                },
                {
                    path: SYSTEM_PATH.GENERAL_SETTING_ACT,
                    title: SIDEBAR_TITLE.GENERAL_SETTING.ACT_OF_SPECIFIED_COMMERCIAL_TRANSACTION
                },
                {
                    path: SYSTEM_PATH.GENERAL_SETTING_PRIVACY,
                    title: SIDEBAR_TITLE.GENERAL_SETTING.PRIVACY_POLICY
                },
                {
                    path: SYSTEM_PATH.GENERAL_SETTING_ABOUT,
                    title: SIDEBAR_TITLE.GENERAL_SETTING.ABOUT_US
                }
            ]
        },
        {
            show: true,
            icon: 'fa-sliders',
            path: SYSTEM_PATH.OTHER_SETTING,
            title: SIDEBAR_TITLE.OTHER_SETTING,
            condition: userInfo?.role !== ROLE.STAFF.key
        }
    ];

    return (
        <Sidebar
            width="265px"
            collapsedWidth="65px"
            defaultCollapsed={localStorage.getItem('collapsedMenu') === 'true'}
        >
            <Menu>
                <MenuItem className="ps-menu-logo" onClick={handleMenuClick}>
                    <img src="/images/logo.png" alt="logo-app" className="logo-app" />
                    <div className="text-app">RAISE管理画面</div>
                </MenuItem>
            </Menu>
            {userInfo?.email && (
                <Menu className="ps-menu-root-content" closeOnClick={true}>
                    {menuItems.map((item, index) => {
                        if (!item.show || (item.condition === false)) return null;

                        if (item.isSubMenu) {
                            return (
                                <SubMenu
                                    key={index}
                                    label={item.title}
                                    icon={<i className={`fa-solid ${item.icon}`}></i>}
                                >
                                    {item.subMenuItems.map((subItem, subIndex) => (
                                        <MenuItem
                                            key={subIndex}
                                            component={<Link to={subItem.path} />}
                                            onClick={() => !isActive(subItem.path) && handleMenuClick()}
                                            active={isActive(subItem.path)}
                                        >
                                            {subItem.title}
                                        </MenuItem>
                                    ))}
                                </SubMenu>
                            );
                        }

                        return (
                            <MenuItem
                                key={index}
                                icon={<i className={`fa-solid ${item.icon}`}></i>}
                                component={<Link to={item.path} />}
                                onClick={() => !isActive(item.path) && handleMenuClick()}
                                active={isActive(item.path)}
                            >
                                {item.title}
                            </MenuItem>
                        );
                    })}
                </Menu>
            )}
        </Sidebar>
    );
});

export default SideBar;
