import { flow, makeObservable, observable } from 'mobx';
import BaseStore from './BaseStore';
import PaymentApi from '../apis/PaymentApi';
class PaymentStore extends BaseStore {
    transactionList = [];
    transactionDetail = {};
    totalAirdrop = 0;

    constructor(rootStore) {
        super();
        makeObservable(this, {
            transactionList: observable,
            transactionDetail: observable,
            totalAirdrop: observable,
            // api method ...
            getAllTransaction: flow.bound,
            getDetailsTransaction: flow.bound,
            confirmChangePaymentStatus: flow.bound,
            updateAIRDropStatus: flow.bound,
            cancelPurchase: flow.bound,
            exportTransactionCsv: flow.bound
        })
        this.rootStore = rootStore;
        this.api = new PaymentApi();
    }

    *getAllTransaction(searchParams) {
        try {
            const { size, page, sortDir, sortKey } = this.paging;
            const payload = { size, page, sortDir, sortKey, ...searchParams };
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getAllTransaction, payload);
            if (res?.ok) {
                this.transactionList = res?.data?.elements || [];
                this.totalAirdrop = res?.data?.totalAirdrop || 0;
                this.setAttrObservable('paging', res?.data?.paginate, true, false);
            }
        } catch (error) {
            console.log(error);
        }
    }

    *getDetailsTransaction(id) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.getDetailsTransaction, id);
            if (res?.ok) {
                this.transactionDetail = res?.data || {};
                return res;
            }
        } catch (error) {
            console.log(error);
        }
    }

    *confirmChangePaymentStatus(id) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.confirmChangePaymentStatus, id);
            if (res?.ok) {
                return true;
            }
        } catch (error) {
            console.log(error);
        }
    }

    *updateAIRDropStatus(id, payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.updateAIRDropStatus, {id, payload});
            if (res?.ok) {
                return true;
            }
        } catch (error) {
            console.log(error);
        }
    }

    *cancelPurchase(id) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.cancelPurchase, id);
            if (res?.ok) {
                return true;
            }
        } catch (error) {
            console.log(error);
        }
    }

    *exportTransactionCsv(payload) {
        try {
            const res = yield this.rootStore.apiStore.call(this.api, this.api.exportTransactionCsv, payload);
            if (res?.ok) {
                return true;
            }
        } catch (error) {
            console.log(error);
        }
    }

    clean() {
        super.clean();
        this.transactionList = [];
        this.transactionDetail = {}
    }
}

export default PaymentStore;