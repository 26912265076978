/* eslint-disable indent */
import ApiStore from './ApiStore';
import AuthStore from './AuthStore';
import InquiryStore from './InquiryStore';
import ModalStore from './ModalStore';
import UserStore from './UserStore';
import GeneralSettingStore from './GeneralSettingStore';
import SubscriptionContractStore from './SubscriptionContractStore';
import CardStore from './CardStore';
import CardTypeStore from './CardTypeStore'
import AccountStore from './AccountStore';
import PaymentStore from './PaymentStore';
import DashboardStore from './DashboardStore';
import { action, makeAutoObservable } from 'mobx';

class RootStore {

        constructor() {
                makeAutoObservable(this, {
                        clearAllStore: action.bound
                });
                this.modalStore = new ModalStore(this);
                this.apiStore = new ApiStore(this);
                this.authStore = new AuthStore(this);
                this.userStore = new UserStore(this);
                this.inquiryStore = new InquiryStore(this);
                this.generalSettingStore = new GeneralSettingStore(this);
                this.subscriptionContractStore = new SubscriptionContractStore(this);
                this.cardStore = new CardStore(this);
                this.cardTypeStore = new CardTypeStore(this);
                this.accountStore = new AccountStore(this);
                this.paymentStore = new PaymentStore(this);
                this.dashboardStore = new DashboardStore(this);
        }

        clearAllStore() {
                this.userStore.clean();
                this.inquiryStore.clean();
                this.subscriptionContractStore.clean();
                this.cardStore.clean();
                this.cardTypeStore.clean();
                this.paymentStore.clean();
                this.accountStore.clean();
        }
}

export default RootStore;