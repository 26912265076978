import { observer } from 'mobx-react';
import { useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from '../../core/utils/yupValidate';
import { useStore } from '../../core/utils/hook';
import { MSG, SCREEN_MODE, ROLE, AVATAR_DEFAULT_URL, TEXT, SYSTEM_PATH, SIDEBAR_TITLE, USER_STATUS } from '../../core/configs/constants';
import { useEffect } from 'react';
import { DateTimeCalendarCustom, ReactNotifications } from '../../components';
import moment from 'moment';
import { loadFilePreview, loadURLPreview } from '../../core/utils/browser';
import { furiganaRegex } from '../../core/utils/common';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';

const SettingUserScreen = observer((props) => {

    const { id, mode } = props

    // others
    const navigate = useNavigate();
    const location = useLocation();

    const stateCollapseAdmin = location?.state?.stateCollapseAdmin ? location.state.stateCollapseAdmin : false;
    const stateCollapseMember = location?.state?.stateCollapseMember ? location.state.stateCollapseMember : false;

    // const { id } = useParams();

    // props
    // const { mode } = props;
    const titlePage = mode === SCREEN_MODE.ADD ? SIDEBAR_TITLE.CARD_ADD : '';

    // store
    const {
        userStore: { createUser, getUser, user, setAttrObservable, updateUser }, authStore: {userInfo} } = useStore();

    // state
    const validateSchema = yup.object().shape({
        userName: yup.string().trim().max(12, MSG['error.length_12']).required(MSG['error.required']),
        fullName: yup.string().trim().max(24, MSG['error.length_24']).required(MSG['error.required']),
        fullNameFuri: yup.string().matches(furiganaRegex, MSG['error.furigana_format'])
            .max(24, MSG['error.length_24']).nullable(),
        email: yup.string().email(MSG['error.email_format']).required(MSG['error.required']),
        birthday: yup.lazy((value) => {
            if (value === null) {
                return yup.mixed();
            }
            return yup.string().nullable()
        }),
        phoneNumber: yup.string().nullable().max(11, MSG['error.phone_number_length'])
            .checkInputOnlyContainDigitsOrEmpty(MSG['error.phone_number_format']),
        address: yup.string().max(54, MSG['error.address_length']).nullable(),
        image: yup.mixed().checkFileImage(MSG['error.image_format']).transform((v) => {
            if (v instanceof FileList) {
                return v.length < 1 ? undefined : v[0];
            } else {
                return v;
            }
        }),
        role: yup.number().required(MSG['error.required']),
        note: yup.string().nullable(),
        dropAddress: yup.array().of(yup.string().trim().required(MSG['error.required'])).nullable()
    })
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
        reset,
        getValues,
        setValue,
        trigger,
        watch,
        control
    } = useForm({
        resolver: yupResolver(validateSchema), mode: 'onChange',
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'dropAddress',
    })


    const watchImage = watch('image');
    const watchStatus = watch('status');
    const watchRole = watch('role');
    // lifecycle

    useEffect(() => {
        const { fullName, fullNameFuri, phoneNumber, birthday, address, avatar, userName, email, role, note, status,
            dropAddress } = user;

        reset((mode === SCREEN_MODE.EDIT && id && user.id) ?
            {
                fullName, fullNameFuri, phoneNumber, birthday, address, image: avatar, userName, email, status: String(status),
                role, note, dropAddress
            }
            :
            { role: String(ROLE.MEMBER.key), status: USER_STATUS.WAITING_CONFIRM.key });


    }, [user])

    useEffect(() => {
        if (watchImage instanceof FileList) {
            if (watchImage.length > 0) {
                loadFilePreview(watchImage[0], 'preview-image');
            } else {
                loadURLPreview(AVATAR_DEFAULT_URL, 'preview-image');
            }
        } else if (typeof watchImage === 'string' || watchImage instanceof String) {
            loadURLPreview(watchImage || AVATAR_DEFAULT_URL, 'preview-image');
        } else {
            loadURLPreview(AVATAR_DEFAULT_URL, 'preview-image');
        }
    }, [watchImage])

    // function
    const onRemoveImage = () => {
        setValue('image', undefined, { shouldValidate: true });
    }

    const onRemoveDropID = (id) => {
        remove(id);
    }

    const onSubmitSettingUser = async (data) => {
        let res;
        const { fullName, fullNameFuri, phoneNumber, birthday = '', address, image, userName, email, role, note, status,
            dropAddress = [] } = data;

        if (mode === SCREEN_MODE.ADD) {
            res = await createUser({
                fullName, fullNameFuri, phoneNumber, birthday, address,
                avatar: image, userName, email, role, note, dropAddress
            });
        } else if (mode === SCREEN_MODE.EDIT) {
            res = await updateUser(id, {
                fullName, fullNameFuri, phoneNumber, birthday, address,
                avatar: image, userName, email, note, status, dropAddress
            });
        }

        if (res) {
            ReactNotifications('success', mode === 0 ? MSG['inform.success.create'] : MSG['inform.success.update']);
            mode === SCREEN_MODE.ADD ? navigate(SYSTEM_PATH.USER_MANAGEMENT) : <></>;
        }
    }

    return (
        <div className='setting-user-screen'>
            <div className='container-title'>{titlePage}</div>
            <div className={classNames(mode === SCREEN_MODE.ADD && 'container-content-form')}>
                <form onSubmit={handleSubmit(onSubmitSettingUser)}>
                    <div className='row'>
                        <div className='col-6'>
                            {
                                mode === SCREEN_MODE.EDIT &&
                                <div className='row'>
                                    <label className='col-3 field-required'>ユーザーID</label>
                                    <div className='col-9 pd-0 fw-bolder'>
                                        {user?.code}
                                    </div>
                                </div>
                            }
                            {mode === SCREEN_MODE.ADD ?
                                (<div className='row mg-t-20'>
                                    <label className='col-3 field-required'>ユーザーネーム</label>
                                    <div className='col-9 pd-0'>
                                        <input {...register('userName')} maxLength={12} className='w-100' />
                                        {
                                            errors.userName &&
                                            <div className='text-danger fs-error mg-t-5 pd-0'>{errors.userName?.message}</div>
                                        }
                                    </div>
                                </div>) : (
                                    <div className='row mg-t-20'>
                                        <label className='col-3 field-required'>ユーザーネーム</label>
                                        <div className='col-9 pd-0 fw-bolder'>
                                            {user?.userName}
                                        </div>
                                    </div>
                                )}

                            <div className='row mg-t-20'>
                                <label className='col-3 field-required'>名前-本名</label>
                                <div className='col-9 pd-0'>
                                    <input {...register('fullName')} maxLength={24} className='w-100' />
                                    {
                                        errors.fullName &&
                                        <div className='text-danger fs-error mg-t-5 pd-0'>{errors.fullName?.message}</div>
                                    }
                                </div>
                            </div>
                            <div className='row mg-t-20'>
                                <label className='col-3'>名前-フリガナ</label>
                                <div className='col-9 pd-0'>
                                    <input {...register('fullNameFuri')} maxLength={24} className='w-100' />
                                    {
                                        errors.fullNameFuri &&
                                        <div className='text-danger fs-error mg-t-5 pd-0'>{errors.fullNameFuri?.message}</div>
                                    }
                                </div>
                            </div>
                            <div className='row mg-t-20'>
                                <label className='col-3 field-required'>メールアドレス</label>
                                <div className='col-9 pd-0'>
                                    <input {...register('email')} className='w-100' />
                                    {
                                        errors.email &&
                                        <div className='text-danger fs-error mg-t-5 pd-0'>{errors.email?.message}</div>
                                    }
                                    {/* {
                                        user?.newEmail &&
                                        <div className="text-danger fs-error mg-t-5 pd-0">
                                            {
                                                user?.confirmChange ?
                                                    <span>※ You are requesting to change email. 
                                                    Please go to email "{user.email}" and follow the instructions.</span>
                                                    :
                                                    <span>※ There is a request to change the email to "{user.newEmail}". 
                                                    Please check your email inbox for confirmation.</span>
                                            }
                                        </div>
                                    } */}
                                </div>
                            </div>
                            <div className='row mg-t-20'>
                                <label className='col-3'>生年月日</label>
                                <div className='col-9 pd-0'>
                                    <DateTimeCalendarCustom
                                        startDate={getValues('birthday') || null}
                                        maxDate={new Date()}
                                        onChange={date => {
                                            setValue('birthday', date ? moment(date).format('YYYY/MM/DD') : null, { shouldValidate: true });
                                        }}
                                    />
                                    {
                                        errors.birthday &&
                                        <div className='text-danger fs-error mg-t-5 pd-0'>{errors.birthday?.message}</div>
                                    }
                                </div>
                            </div>
                            <div className='row mg-t-20'>
                                <label className='col-3'>電話番号</label>
                                <div className='col-9 pd-0'>
                                    <input {...register('phoneNumber')} maxLength={11} className='w-100' />
                                    {
                                        errors.phoneNumber &&
                                        <div className='text-danger fs-error mg-t-5 pd-0'>{errors.phoneNumber?.message}</div>
                                    }
                                </div>
                            </div>
                            <div className='row mg-t-20'>
                                <label className='col-3'>住所</label>
                                <div className='col-9 pd-0'>
                                    <input {...register('address')} maxLength={54} className='w-100' />
                                    {
                                        errors.address &&
                                        <div className='text-danger fs-error mg-t-5 pd-0'>{errors.address?.message}</div>
                                    }
                                </div>
                            </div>
                            <div className='row mg-t-20'>
                                <label className='col-3 field-required'>役割</label>
                                <div className='col-9 d-flex align-items-center flex-gap-30 pd-l-0'>
                                    {
                                        mode === SCREEN_MODE.ADD ?
                                            Object.keys(ROLE).filter((_, idx) => idx !== 0).map(e =>
                                                <div key={ROLE[e].key} className='d-flex align-items-center flex-gap-5'>
                                                    <input type={'radio'} value={ROLE[e].key} {...register('role')} id={`role-${ROLE[e].key}`} />
                                                    <label htmlFor={`role-${ROLE[e].key}`}>{ROLE[e].label}</label>
                                                </div>)

                                            :
                                            Object.values(ROLE).filter(role => role.key === getValues('role'))?.[0]?.label
                                    }
                                </div>
                            </div>
                            {getValues('role') == ROLE.MEMBER.key && <div className='row mg-t-20'>
                                <label className='col-3'>Drop先アドレス</label>
                                <div className='col-9 pd-0'>
                                    {fields.map((field, index) => {
                                        return (
                                            <>
                                                <div key={field.id} className='drop-item w-100 mg-b-10'>
                                                    <input className='w-100' {...register(`dropAddress.${index}`)} />
                                                    <div className='drop-remove btn-icon-input btn-clear-input'
                                                        onClick={() => onRemoveDropID(index)}>
                                                        <i className="fa-solid fa-xmark fs-heading-normal text-color-cs-red"></i>
                                                    </div>
                                                </div>
                                                {
                                                    errors.dropAddress && errors.dropAddress[index] &&
                                                    <div className='text-danger fs-error mg-t-5 pd-0'>{errors.dropAddress[index]?.message}</div>
                                                }
                                            </>
                                        )
                                    })}
                                    <div className={classNames('text-color-cs-4', getValues('dropAddress')?.length && 'mg-t-15')}
                                        role='button' onClick={() => {
                                            append(' ');
                                        }
                                        }>
                                        <i className="fa-solid mg-r-5 fa-circle-plus" />Drop先アドレスを追加する</div>
                                </div>
                            </div>}
                            {
                                mode === SCREEN_MODE.EDIT && userInfo?.role !== ROLE.STAFF.key &&
                                ([ROLE.ADMIN.key, ROLE.STAFF.key].includes(getValues('role'))) &&
                                <>
                                    <div className='row mg-t-20'>
                                        <label className='col-3'>ステータス</label>
                                        <div className='col-9 pd-0'>
                                            {
                                                getValues('status') == USER_STATUS.WAITING_CONFIRM.key ?
                                                    USER_STATUS.WAITING_CONFIRM.label
                                                    :
                                                    <select {...register('status')} className='w-100'>
                                                        {
                                                            [
                                                                USER_STATUS.INACTIVE,
                                                                USER_STATUS.ACTIVE
                                                            ].map(status =>
                                                                <option key={status.key} value={status.key}>
                                                                    {status.label}
                                                                </option>)
                                                        }
                                                    </select>
                                            }
                                            {
                                                errors.status &&
                                                <div className='text-danger fs-error mg-t-5 pd-0'>{errors.status?.message}</div>
                                            }
                                        </div>
                                    </div>
                                </>

                            }
                        </div>
                        <div className='col-6 d-flex align-items-center flex-column flex-gap-1 justify-content-center'>
                            <div className='position-relative'>
                                <img id='preview-image' className='width-220 height-220 rounded-circle border' />
                                {
                                    ((watchImage instanceof FileList && watchImage.length > 0)
                                        || ((typeof watchImage === 'string' || watchImage instanceof String) && watchImage !== '')) &&
                                    <i onClick={onRemoveImage} role='button'
                                        className='fa fa-light fa-circle-xmark text-danger position-absolute top-0 end-0 font-size-20' />
                                }
                            </div>
                            <input id='image' type={'file'} {...register('image')} accept='image/png, image/jpeg'
                                className='w-100' hidden />
                            <label htmlFor='image' className='text-color-cs-4'>
                                <i className='fa fa-light fa-upload mg-r-5'></i> 写真を選択
                            </label>
                            {
                                errors.image &&
                                <div className='text-danger fs-error mg-t-5 pd-0'>{errors.image?.message}</div>
                            }
                        </div>
                        <div className='row mg-t-20'>
                            <div className='col-12'>
                                <div className='row'>
                                    <label className='col-1-5'>メモ</label>
                                    <div className='col-10-5 pd-l-0'>
                                        <textarea {...register('note')} className='w-100 min-height-150' />
                                        {
                                            errors.note &&
                                            <div className='text-danger fs-error mg-t-5 pd-0'>{errors.note?.message}</div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex justify-content-center align-items-center mg-t-40'>
                        <button type='button' className='btn-default btn-cancel width-150'
                            onClick={() => 
                                navigate(SYSTEM_PATH.USER_MANAGEMENT, { state: { stateCollapseAdmin, stateCollapseMember } })}>
                            {TEXT.CANCEL}
                        </button>
                        <button type='submit' className='btn-default btn-bg-cs-1 width-150 mg-l-50' disabled={isSubmitting}>
                            {SCREEN_MODE.ADD === mode ? TEXT.CREATE : TEXT.UPDATE}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
})

export default SettingUserScreen;
