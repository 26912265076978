import { forwardRef, useMemo, useRef, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import ja from 'date-fns/locale/ja';
import moment from 'moment';
import classNames from 'classnames';
registerLocale('ja', ja);

import './style.scss';

const DateTimeCalendarCustom = (props) => {

    // ref
    const datePickerRef = useRef();

    // props
    const { 
        className,
        startDate,
        selectYearFrom = moment().get('year') - 100,
        selectYearTo = moment().get('year'),
        onChange,
        showTimeSelect = false,
        timeIntervals = 15,
        timeCaption = '時間',
        dateFormat,
        minDate,
        maxDate,
        iconInput,
        isEditInput = false,
        showTimeInput = false,
        hideIconRemove = false
    } = props;

    // state
    const [datetimeSelected, setDatetimeSelected] = useState(startDate);

    // function
    const onChangeDatetimeSelected = (date) => {
        setDatetimeSelected(date);
        if(!showTimeInput) {
            onChange?.(date);
        }
    } 

    // custom header
    const years = Array.from({ length: selectYearTo - selectYearFrom + 1 }).map((e, idx) => selectYearTo - idx);
    const months = Array.from({ length: 12 }).map((e, idx) => idx + 1);
    const customHeader = ({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled
    }) => (
        <div className='d-flex align-items-center justify-content-between pd-lr-10 mg-b-10 react-datepicker-header-custom'>
            <button type='button' onClick={decreaseMonth} disabled={prevMonthButtonDisabled} className='rdrNextPrevButton rdrPprevButton'>
                <i/>
            </button>

            <div className='d-flex align-items-center justify-content-between flex-gap-15'>
                <select
                    value={moment(date).get('month') + 1}
                    onChange={({ target: { value } }) =>
                        changeMonth(value - 1)
                    }
                >
                    {months.map((option) => (
                        <option key={option} value={option}>
                            {option}月
                        </option>
                    ))}
                </select>

                <select
                    value={moment(date).get('year')}
                    onChange={({ target: { value } }) => changeYear(value)}
                >
                    {years.map((option) => (
                        <option key={option} value={option}>
                            {option}
                        </option>
                    ))}
                </select>
            </div>

            <button type='button' onClick={increaseMonth} disabled={nextMonthButtonDisabled} className='rdrNextPrevButton rdrNextButton'>
                <i/>
            </button>
        </div>
    )

    // custom day
    const renderDayContents = (day, date) => {
        return <span>{moment(date).format('DD')}</span>;
    };

    // custom input
    const CustomInput = forwardRef(({ value, onClick }, ref) => (
        <div className='w-100' ref={ref}>
            <input className='w-100' 
                value={
                    !showTimeInput ? value : 
                        (startDate ? moment(new Date(startDate)).format('YYYY/MM/DD HH:mm') : '')
                } 
                readOnly={!isEditInput}
                onClick={onClick}
            />
            <div className='btn-icon-input'>
                {iconInput || <i className='fa-solid fa-calendar-days' onClick={onClick}></i> }
            </div>
            {
                value && !hideIconRemove &&
                <div className='btn-icon-input btn-clear-input' onClick={() => onChange(null)}>
                    <i className="fa-solid fa-xmark"></i>
                </div>
            }
        </div>
    ));

    const CustomTimeInput = ({ value, onChange: onChangeValue }) => {
        
        // memo
        const [minutes, seconds] = useMemo(() => {
            if(!value) return ['00', '00'];
            return value.split(':');
        }, [value]);

        return (
            <div className='d-flex align-items-center flex-gap-20'>
                <div className='d-flex align-items-center flex-gap-10'>
                    <div className='react-datepicker-time__caption'>時</div>
                    <select style={{'width': '50px', 'margin': '0px'}} value={minutes} onChange={e => onChangeValue(`${e.target.value}:${seconds}`)}>
                        {Array.from({length: 24}).map((_, index) => index < 10 ? `0${index}` : index).map(e => (
                            <option key={e} value={e}>{e}</option>
                        ))}
                    </select>
                </div>
                <div className='d-flex align-items-center flex-gap-10'>
                    <div className='react-datepicker-time__caption'>分</div>
                    <select style={{'width': '50px', 'margin': '0px'}} value={seconds} onChange={e => onChangeValue(`${minutes}:${e.target.value}`)}>
                        {Array.from({length: 60}).map((_, index) => index < 10 ? `0${index}` : index).map(e => (
                            <option key={e} value={e}>{e}</option>
                        ))}
                    </select>
                </div>
                <button 
                    type='button' 
                    className='btn btn-bg-cs-1' 
                    style={{'width': '80px', 'minWidth': '50px'}}
                    onClick={() => {
                        onChange?.(datetimeSelected);
                        datePickerRef.current?.setOpen?.(false);
                    }}
                >
                    反映
                </button>
            </div>
        )
    };

    return (
        <DatePicker
            ref={datePickerRef}
            locale='ja'
            calendarClassName={classNames('react-datepicker-custom', className)}
            dateFormat={dateFormat ? dateFormat : (showTimeSelect || showTimeInput) ? 'yyyy/MM/dd HH:mm' : 'yyyy/MM/dd'}
            selected={showTimeInput ? (datetimeSelected ? new Date(datetimeSelected) : null) : (startDate ? new Date(startDate) : null)} 
            onChange={onChangeDatetimeSelected}
            renderCustomHeader={customHeader}
            renderDayContents={renderDayContents}
            showTimeSelect={showTimeSelect}
            timeIntervals={timeIntervals}
            timeCaption={timeCaption}
            minDate={minDate}
            maxDate={maxDate}
            customInput={<CustomInput/>}
            showTimeInput={showTimeInput}
            customTimeInput={<CustomTimeInput/>}
            timeInputLabel=''
            shouldCloseOnSelect={!showTimeInput}
            onCalendarOpen={() => setDatetimeSelected(startDate)}
        />
    )
}

export default DateTimeCalendarCustom;